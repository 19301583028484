<template><div><h1 id="compiling-your-own-firmware" tabindex="-1"><a class="header-anchor" href="#compiling-your-own-firmware"><span>Compiling Your Own Firmware</span></a></h1>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>Building and flashing your own firmware has the potential to brick your device. Do not do this unless you are sure you know what you're doing <strong>and have a way to recover from a bad flash</strong>. Some level of knowledge with using the Linux command line is required.</p>
</div>
<ol>
<li>
<p><strong>Install tools and libraries needed for coreboot/edk2:</strong></p>
<ul>
<li>Debian/Ubuntu based distros: <code v-pre>sudo apt install -y bison build-essential curl flex git gnat imagemagick libncurses5-dev m4 nasm python-is-python3 uuid-dev zlib1g-dev libssl-dev</code></li>
<li>Arch based distros: <code v-pre>sudo pacman --needed -S base-devel curl git gcc-ada ncurses zlib nasm imagemagick</code></li>
<li>Redhat based distros: <code v-pre>sudo dnf install git make gcc-gnat flex bison xz bzip2 gcc g++ ncurses-devel wget zlib-devel patch openssl libuuid-devel nasm texinfo</code></li>
</ul>
</li>
<li>
<p><strong>Clone the repository:</strong></p>
<ul>
<li><code v-pre>git clone https://github.com/mrchromebox/coreboot.git</code></li>
</ul>
</li>
<li>
<p><strong>Clone the submodules:</strong></p>
<ul>
<li><code v-pre>cd coreboot</code></li>
<li><code v-pre>git submodule update --init --checkout --recursive</code></li>
</ul>
</li>
<li>
<p><strong>Build the coreboot toolchain</strong></p>
<ul>
<li><code v-pre>make crossgcc-i386 CPUS=$(nproc)</code></li>
</ul>
</li>
<li>
<p><strong>Make changes now, if needed.</strong></p>
<ul>
<li>Common changes include:
<ul>
<li>Replacing the default logo (<code v-pre>Documentation/coreboot_logo.bmp</code>)</li>
<li>Enabling serial debug output (<code v-pre>CONFIG_CONSOLE_SERIAL=y</code>)</li>
</ul>
</li>
</ul>
</li>
<li>
<p><strong>Build the firmware</strong></p>
<ul>
<li><code v-pre>./build-uefi.sh &lt;boardname&gt;</code>
<ul>
<li>For example, <code v-pre>./build-uefi.sh panther</code></li>
<li>If successful, the compiled image will be found in <code v-pre>~/dev/roms</code></li>
</ul>
</li>
</ul>
</li>
<li>
<p><strong>See <RouteLink to="/docs/firmware/manual-flashing.html">Flashing Manually</RouteLink> to flash</strong></p>
</li>
</ol>
</div></template>


